<template>
  <div class="view-login auth">
    <nav class="container">
      <div class="d-flex justify-content-between align-items-center mb-4 pt-4">
        <img class="logo" src="@/assets/logo.png" />
        <div>
          <!-- <router-link class="btn btn-outline-light" :to="{ name: 'register' }">
            Cadastre-se
          </router-link> -->
        </div>
      </div>
    </nav>

    <div class="container">
      <div class="row">
        <div class="col-md-7 col-lg-5 mx-auto">
          <form class="card" @submit.prevent="submit">
            <div class="card-body">
              <h3 class="text-center">Entrar</h3>

              <p class="text-center mb-4 pb-1">
                Informe seu e-mail e senha para fazer login.
              </p>

              <div v-if="errors.non_field_errors && errors.non_field_errors.length" class="non-field-error my-4">
                {{ errors.non_field_errors[0] }}
              </div>

              <form-group
                v-model="email"
                id="email"
                type="email"
                placeholder="Digite o e-mail cadastrado"
                :errors="errors.email"
              />

              <div class="position-relative mb-3">
                <form-group
                  class="mb-0"
                  v-model="password"
                  id="password"
                  :type="passwordInputType"
                  placeholder="Digite a sua senha"
                  :errors="errors.password"
                />
                <show-hide-password v-model="passwordInputType" />
              </div>

              <p class="text-right mb-2">
                <button
                  type="button"
                  @click="forgotDialog = true"
                  class="btn btn-sm btn-link text-white"
                >
                  Esqueceu a senha?
                </button>
              </p>

              <b-form-checkbox
                class="mb-4"
                id="remember"
                v-model="remember"
                name="remember"
                :value="true"
              >
                <small>
                  Lembrar de mim
                </small>
              </b-form-checkbox>

              <button
                class="btn btn-block btn-lg btn-success"
                type="submit"
                :disabled="loading"
              >
                <loading :show="loading">
                  <strong>Entrar</strong>
                </loading>
              </button>

              <!-- <facebook-login app-id="766174930801321" @login="facebookLogin">
                <template v-slot:logo>
                  <i class="icon-facebook"></i>
                </template>
                <template v-slot:login>
                  Entrar com o Facebook
                </template>
              </facebook-login> -->
            </div>
          </form>
        </div>
      </div>
    </div>

    <forgot-password v-model="forgotDialog" />
  </div>
</template>

<script>
import ForgotPassword from '@/components/login/ForgotPassword'
import ShowHidePassword from '@/components/ShowHidePassword'
// import FacebookLogin from 'vue-facebook-login-component'

export default {
  name: 'login',
  components: {
    ForgotPassword,
    // FacebookLogin,
    ShowHidePassword
  },
  data () {
    return {
      remember: false,
      email: window.localStorage.getItem('login_email') ? window.localStorage.getItem('login_email') : '',
      password: window.localStorage.getItem('login_password') ? window.localStorage.getItem('login_password') : '',
      passwordInputType: 'password',
      errors: {
        email: null,
        password: null,
        non_field_errors: null
      },
      loading: false,
      forgotDialog: false
    }
  },
  methods: {
    postLogin () {
      this.$store.dispatch('postLogin', false).then(() => {
        this.$router.replace({ name: 'home' })
      })
    },
    facebookLogin (response) {
      if (response && response.authResponse) {
        this.$store
          .dispatch('user/facebookLogin', response.authResponse)
          .then(this.postLogin)
      }
    },
    submit () {
      this.$message.hide()

      this.loading = true

      this.$store
        .dispatch('user/login', {
          email: this.email,
          password: this.password
        })
        .then(() => {
          if (this.remember) {
            window.localStorage.setItem('login_email', this.email)
            window.localStorage.setItem('login_password', this.password)
          }
          this.postLogin()
        })
        .catch(error => {
          this.errors = error.response.data
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0])
          }
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.view-login {
  .logo {
    max-width: 120px;
  }

  ::v-deep .show-hide-password {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
</style>
