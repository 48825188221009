<template>
  <b-modal
    @change="change"
    :visible="visible"
    hide-footer
    hide-header
    centered
  >
    <div class="forgot-password p-2">
      <h3 class="text-center px-4 pt-2">
        Digite seu e-mail de cadastro
      </h3>

      <div class="text-center px-4 pt-2 mb-2 pb-4 text">
        Você receberá, em seu e-mail, o link para redefenir a sua senha
      </div>

      <form @submit.prevent="submit">
        <form-group
          v-model="email"
          id="email"
          type="email"
          placeholder="Digite a seu e-mail"
          :errors="errors.email"
        />

        <div class="row">
          <div class="col pr-4">
            <button
              class="btn btn-block btn-outline-secondary btn-lg"
              type="button"
              @click="close"
            >
              Fechar
            </button>
          </div>
          <div class="col pl-4">
            <button
              class="btn btn-block btn-primary btn-lg"
              type="submit"
              :disabled="loading"
            >
              <b-spinner v-show="loading" variant="light" small></b-spinner>
              <span v-show="!loading">Enviar</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      defaul: false
    }
  },
  data () {
    return {
      loading: false,
      email: '',
      errors: {
        email: null
      }
    }
  },
  model: {
    prop: 'visible',
    event: 'change'
  },
  methods: {
    close () {
      this.$emit('change', false)
    },
    change (val) {
      this.$emit('change', val)
    },
    submit () {
      this.loading = true

      this.$store
        .dispatch('user/forgot', { email: this.email })
        .then(() => {
          this.loading = false
          this.$message.success('Enviado com sucesso, confira o seu e-mail')
          this.close()
        })
        .catch(error => {
          this.errors = error.response.data
          if (error.response.data.message) {
            this.$message.error(error.response.data.message)
          }
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.forgot-password {
  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  .text {
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.4);
  }

  ::v-deep .form-control {
    border: 1px solid #6c757d !important;
  }
}
</style>
